<template>
  <div style="width: 100%">
    <div class="container">
      <!-- 头部 -->
      <div class="header">
        <router-link to="/"
          ><img src="../../static/img/Vector.png" alt=""
        /></router-link>
        <p>停车位预约</p>
      </div>
    </div>
    <div class="logo">
      <img src="../../static/img/Logo.png" alt="" />
      <p>预约成功</p>
      <p style="color: #0e0e0e; font-size: 16px; margin-top: 10px">
        深圳宝德仕电玩制造有限公司欢迎您
      </p>
    </div>
    <!-- 信息显示 -->
    <div class="info">
      <div class="infoItem">
        <p>称呼:</p>
        <p>{{ data.owner_name }}</p>
      </div>
      <div class="infoItem">
        <p>手机号码:</p>
        <p>{{ data.phone_number }}</p>
      </div>
      <div class="infoItem">
        <p>车牌号码:</p>
        <div class="carNumberShow">
          <div class="numberItem" v-for="(item, index) in data.car_numer_perfix" :key="index">{{item}}</div>
          <div class="point"></div>
          <div class="numberItem" v-for="(item, index) in data.car_numer_suffix" :key="index">{{item}}</div>
          <div class="numberItem" style="font-size: 7px" :class="{'newEnergy': data.car_type == '0', 'disableItem': data.car_type == '1'}">新能源</div>
        </div>
      </div>
      <div class="infoItem">
        <p>停车日期:</p>
        <p>{{ year }} 年 {{ month }} 月 {{ day }} 日 {{ times[data.time] }}</p>
      </div>
      <div class="nextStepBtn disableItem" @click.once="cancelTheAppointment">
        修改信息
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs';

export default {
  data() {
    return {
      data: {},
      times: [
        "8:00 - 12:00",
        "12-00 - 16:00",
        "16:00 - 20:00",
        "20:00 - 23:00",
        /* "15:00 - 16:00",
        "16:00 - 17:00", */
      ],
      year: "",
      month: "",
      day: "",
    };
  },
  methods: {
    cancelTheAppointment() {
      this.$axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/Parking/deleteReserveByID',
        data: qs.stringify({ID: this.data.ID}),
      }).then(
        (res) => {
          console.log(res)
          this.$router.push({
            name:'ParkingReservation',
          })
        }
      )
    },
  },
  created() {
    this.data = this.$route.params.data;
    console.log(this.data);
    this.year = this.data.date.slice(0, 4);
    this.month = this.data.date.slice(5, 7);
    this.day = this.data.date.slice(8, 10);
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.container {
  position: relative;
  transition: all 0.5s;
}

.header {
  position: relative;
  background-color: #1f89eb;
  height: 50px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.header img {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(0%, -50%) rotateY(180deg);
  height: 16px;
}

.header > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.logo {
  height: 300px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #17a2ae;
}

.logo img {
  height: 220px;
  display: block;
  margin: auto;
}

.info {
  margin-top: 30px;
  font-size: 16px;
}

.infoItem {
  height: 25px;
  margin-bottom: 30px;
  position: relative;
}

.infoItem p:nth-child(1) {
  font-weight: bold;
  position: absolute;
  left: 10%;
}

.infoItem p:nth-child(2) {
  position: absolute;
  left: 35%;
}

.point {
  position: relative;
  top: 13px;
  background: #09a6b5;
  height: 4px;
  width: 4px;
  overflow: hidden;
  border-radius: 50%;
}

.carNumberShow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 55%;
  height: 30px;
  position: absolute;
  left: 35%;
  top: -5px;
}

.numberItem {
  width: 25px;
  height: 30px;
  border: 1.2px solid #c4c4c4;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  border-radius: 5px;
}

.disableItem {
  background: #ffffff;
  border: 1px solid #f4f4f5;
  color: #dbdbdb;
}

.newEnergy {
  color: #09a6b5;
  border: 1.2px solid #09a6b5;
}

.nextStepBtn {
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
  height: 40px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: #1f89eb;
  border-radius: 50px;
}
</style>